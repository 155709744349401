//
//
//
//
//
//
//
//

import { useCache, CacheTagPrefix } from '@odyssey/realtainment-output-cache';
import { ref, computed, onMounted, useContext } from '@nuxtjs/composition-api';
import { Logger, onSSR } from '@odyssey/core';
import { useStoryblokPage, useStoryblokGlobalComponents } from '@odyssey/realtainment';
import { FlexPageController } from '@odyssey/realtainment-api';
import { useStoryblokBridge, useStoryblokApi } from '@storyblok/nuxt-2';
export default {
  components: {
    SFlexLayout: () => import(/* webpackChunkName: "chunk-layout" */ '@/components/templates/SFlexLayout'),
    Spinner: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/Spinner')
  },
  name: 'StoryblokPage',
  props: {
    slug: {
      type: String,
      required: true
    },
    pageType: {
      type: String,
      required: false,
      default: 'storyblok-page'
    }
  },
  setup(props) {
    const {
      query,
      $config,
      redirect,
      app: { nuxt }
    } = useContext();
    const { globalComponents } = useStoryblokGlobalComponents();
    const { fetchStoryblokPage, pageData, loading } = useStoryblokPage();
    const storyblokApi = useStoryblokApi();
    const config = {
      token: $config.storyblok.token
    };

    const pageDataDev = ref(null);
    const data = computed(() => (pageDataDev.value === null ? pageData.value?.content : pageDataDev.value));

    onMounted(async () => {
      if (query.value?._storyblok) {
        try {
          const storyBlokParams = {
            version: 'draft'
          };

          if (query.value?._storyblok_release) {
            storyBlokParams.from_release = query.value._storyblok_release;
          }

          const { data } = await storyblokApi.get('cdn/stories/' + props.slug, storyBlokParams);
          if (data.story?.content) {
            pageDataDev.value = FlexPageController.default(data.story.content, {
              config
            });
          }
          useStoryblokBridge(pageData.value.id, (newData) => {
            if (newData?.content) {
              pageDataDev.value = FlexPageController.default(newData.content, {
                config
              });
            }
          });
        } catch (e) {
          Logger.error('StoryblokPage/onMounted', e);
        }
      } else if (!data.value) {
        await fetchStoryblokPage(props.slug);
        if (!data.value) redirect(302, '/error/');
      }

      setTimeout(() => {
        if (location?.hash) {
          const hash = location.hash.replace('#', '');
          const el = document.getElementsByName(hash)?.[0];
          if (el) el.scrollIntoView({});
        }
      }, 1000);
    });

    onSSR(async () => {
      const { addTags } = useCache();
      await fetchStoryblokPage(props.slug);

      if (!data.value) {
        nuxt.error.call(
          { nuxt: nuxt },
          {
            statusCode: 404,
            message: 'Not found'
          }
        );
        return;
      }

      addTags([{ prefix: CacheTagPrefix.Content, value: props.slug }]);
    });

    return {
      data,
      pageData,
      globalComponents,
      loading
    };
  },
  head() {
    const script = [
      {
        type: 'application/ld+json',
        json: {
          '@context': 'http://schema.org',
          '@type': 'Organization',
          name: this.$config.seo.defaultTitle,
          url: this.$config.server.url,
          description: this.$config.schema.description,
          sameAs: this.$config.schema.sameAs,
          address: {
            '@type': 'PostalAddress',
            ...this.$config.schema.address
          },
          email: this.$config.theme.email,
          image: this.data?.hero?.image,
          logo: this.globalComponents?.header?.logoBlack
        }
      }
    ];

    // Set indexing on production based on allowIndexing flag
    let robots = 'noindex, nofollow';
    if (this.$config.server.isProduction) {
      robots = 'false' && this.data?.allowIndexing ? 'index' : 'noindex';
      robots += this.data?.addNofollow ? ', nofollow' : ', follow';
    }

    const head = this.$nuxtI18nHead({ addDirAttribute: false, addSeoAttributes: true });
    head.title = this.data?.title;
    head.script = script;
    head.link = [
      ...head.link.map((link) => {
        return {
          ...link,
          href: link.href.replace(/%2F/g, '/')
        };
      })
    ];

    if (this.data?.hero?.heroSlogan) {
      head.link.push({
        rel: 'preload',
        as: 'image',
        href: this.data.hero.heroSlogan
      });
    }

    head.meta.push({
      hid: 'description',
      name: 'description',
      content: this.data?.description
    });
    head.meta.push({
      hid: 'robots',
      name: 'robots',
      content: robots
    });

    return head;
  }
};
